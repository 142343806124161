<!-- =========================================================================================
    File Name: Charts.vue
    Description: Chartjs (third-party) - Imports page portions
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <vs-row>
      <vs-col vs-type="flex" vs-justify="space-between">
        <vx-card style="margin:5px; background-color:#00994d; color:white;">
          {{cardDetail.totalUser}}
          <br />Total Users
        </vx-card>
        <vx-card style="margin:5px; background-color:#004d99; color:white;">
          {{cardDetail.totalAdmin}}
          <br />Total Admin
        </vx-card>
        <!-- <vx-card style="margin:5px; background-color:#ff6666; color:white;">
          {{cardDetail.totalReport}}
          <br />Total Reports
        </vx-card>-->
      </vs-col>
    </vs-row>
    <vs-row>
      <!-- <div> -->
      <!-- <dashboard-bar-chart></dashboard-bar-chart> -->
      <!-- </div> -->
    </vs-row>
  </div>
</template>

<script>
import DashboardBarChart from "./DashboardChart.vue";
import { mapActions } from "vuex";
import { dashboardMixin } from "../mixins/dashboardMixin"
export default {
  components: {
    DashboardBarChart,
  },
  mixins: [ dashboardMixin ],
  data: () => ({
    cardDetail: {
      totalUser: 0,
      totalTips: 0,
      totalAdmin: 0,
    },
  }),
  methods: {
    ...mapActions("admin", ["fetchCardDetails"]),
    getDashboardCardDetails() {
      let self = this;
      this.getDashboardData().then((res) => {
        this.cardDetail = res.dashboardData;
      });
    },
  },
  created() {
    this.getDashboardCardDetails();
  },
};
</script>
