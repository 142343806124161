<!-- =========================================================================================
    File Name: ChartBarChart.vue
    Description: Create bar chart
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template >
  <vx-card class="mt-6">
    <vs-col style="display: flex; justify-content: flex-end">
      <vs-select
        label="Select Years"
        vs-w="1"
        v-model="selectedYear"
        @change="setNewSelectedYear(selectedYear)"
      >
        <vs-select-item
          :key="index"
          :value="item.year"
          :text="item.year"
          v-for="(item, index) in yearList"
        />
      </vs-select>
    </vs-col>
    <vs-col>
      <chartjs-component-bar-chart
        :height="200"
        :data="data"
        :options="options"
        v-if="dataAvailable"
      ></chartjs-component-bar-chart>
    </vs-col>
  </vx-card>
</template>

<script>
import ChartjsComponentBarChart from "../views/charts-and-maps/charts/chartjs/charts-components/ChartjsComponentBarChart.vue";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      data: {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: "Subscriptions",
            // backgroundColor: [
            //   "#3e95cd",
            //   "#8e5ea2",
            //   "#3cba9f",
            //   "#e8c3b9",
            //   "#c45850",
            // ],
            backgroundColor: "#ff4d4d",
            data: this.newChartData,
          },
        ],
      },
      options: {
        legend: { display: true },
        responsive: true,
        maintainAspectRatio: false,
        // title: {
        //   display: true,
        //   text: "Predicted world population (millions) in 2050",
        // },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                min: 0,
              },
            },
          ],
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Months",
              },
            },
          ],
        },
      },
      dataAvailable: false,
      selectedYear: 0,
      yearList: [],
    };
  },
  components: {
    ChartjsComponentBarChart,
  },
  methods: {
    ...mapActions("admin", ["fetchChartsDetails", "fetchYearList"]),
    getDashboardChartDetails() {
      let self = this;
      this.fetchChartsDetails(this.selectedYear).then((res) => {
        let newChartData = Array(12).fill(0);
        newChartData.forEach((x, i, arr) => {
          const found = res.data.data.find((y) => {
            if (y.month == i + 1) return y;
          });
          if (found) {
            newChartData[found.month - 1] = found.total;
          }
        });
        this.data.datasets[0].data = newChartData;
        this.dataAvailable = true;
      });
    },
    getYearList() {
      this.fetchYearList().then((res) => {
        this.yearList = res.data.data;
        // this.yearList = [{ year: 2020 }, { year: 3030 }];
      });
    },
    setNewSelectedYear(val) {
      console.log({ val });
      this.selectedYear = val;
      this.dataAvailable = false;
      this.getDashboardChartDetails();
    },
  },
  mounted() {
    this.selectedYear = parseInt(new Date(Date()).getFullYear());
    this.getDashboardChartDetails();
    this.getYearList();
  },
};
</script>
