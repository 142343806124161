import { gql } from "apollo-boost"

export const dashboardMixin = {
    methods:{
        getDashboardData(){
            const getDashboardDataForAdmin = gql`
            query getDashboardDataForAdmin{
                getDashboardDataForAdmin{
                    dashboardData{
                        totalUser
                        totalAdmin
                    }
                }
            }
            `;
            return this.$apollo.query({
                query: getDashboardDataForAdmin,
                fetchPolicy: 'network-only'
            }).then(response => {
                return response.data.getDashboardDataForAdmin
            })
        }
    }
}